import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
} from "firebase/firestore";
import { useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { output, ZodType } from "zod";
import { getDataFromDocumentSnapshot } from "./getDataFromDocumentSnapshot";

export function useValidDocumentData<T extends ZodType<any, any, any>>(
  docRef: DocumentReference<DocumentData> | null,
  zodSchema: T
):
  | [doc: undefined, isLoading: true, error: undefined, snapshot: undefined]
  | [doc: undefined, isLoading: false, error: Error, snapshot: undefined]
  | [
      doc: output<T>,
      isLoading: false,
      error: undefined,
      snapshot: DocumentSnapshot<DocumentData> | undefined
    ] {
  let error: Error | undefined;
  let [snapshot, isLoading, firestoreError] = useDocument(docRef);

  if (!snapshot?.exists()) {
    error = new Error(`Document ${docRef?.path} does not exits`);
  }
  if (firestoreError) {
    error = firestoreError;
  }

  const doc = useMemo(
    () => getDataFromDocumentSnapshot(zodSchema, snapshot),
    [zodSchema, snapshot]
  );

  if (isLoading) return [undefined, true, undefined, undefined];
  if (error) return [undefined, false, error, undefined];

  return [doc, false, undefined, snapshot];
}
